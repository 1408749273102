exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-page-wp-default-template-tsx": () => import("./../../../src/templates/page/WpDefaultTemplate.tsx" /* webpackChunkName: "component---src-templates-page-wp-default-template-tsx" */),
  "component---src-templates-page-wp-template-capital-tsx": () => import("./../../../src/templates/page/WpTemplate_Capital.tsx" /* webpackChunkName: "component---src-templates-page-wp-template-capital-tsx" */),
  "component---src-templates-page-wp-template-home-tsx": () => import("./../../../src/templates/page/WpTemplate_Home.tsx" /* webpackChunkName: "component---src-templates-page-wp-template-home-tsx" */),
  "component---src-templates-page-wp-template-investments-tsx": () => import("./../../../src/templates/page/WpTemplate_Investments.tsx" /* webpackChunkName: "component---src-templates-page-wp-template-investments-tsx" */),
  "component---src-templates-page-wp-template-spacs-tsx": () => import("./../../../src/templates/page/WpTemplate_Spacs.tsx" /* webpackChunkName: "component---src-templates-page-wp-template-spacs-tsx" */),
  "component---src-templates-page-wp-template-swat-tsx": () => import("./../../../src/templates/page/WpTemplate_Swat.tsx" /* webpackChunkName: "component---src-templates-page-wp-template-swat-tsx" */),
  "component---src-templates-page-wp-template-team-tsx": () => import("./../../../src/templates/page/WpTemplate_Team.tsx" /* webpackChunkName: "component---src-templates-page-wp-template-team-tsx" */)
}

