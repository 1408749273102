/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
const smoothscroll = require("smoothscroll-polyfill");

exports.onClientEntry = () => {
  if (typeof window !== "undefined") {
    smoothscroll.polyfill();
  }
};